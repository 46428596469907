import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionBox from "../components/section-box"
import SectionBox2 from "../components/section-box2"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

import styled from "styled-components"

//Ilustration inports
import ilustration1 from "../images/oblakoder-home-1.png"

import Ilustration1 from "../components/ilustratino1"

//Styled Components Import

import SvgBg1 from "../components/styled/SvgBg"
import GridStyled1 from "../components/styled/Grid1"
import ImageBox1 from "../components/styled/ImageBox"

const MigrateToAws = () => (
  <Layout>
    <SEO title="Migrate to AWS" />
    <SvgBg1>
      <GridStyled1>
        <div>
          <ScrollAnimation animateIn="fadeIn" duration={1.8}>
            <h1>
              Migrate to <span>AWS</span>
            </h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={1.3}>
            <h3>Oblakoder</h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={250} duration={1.4}>
            <SectionBox>
              <p>
                <b>Free-up your IT resources</b>
              </p>
            </SectionBox>
            <SectionBox2>
              <p>
                A thousands of organizations already migrated their resources to
                AWS. By doing that, they free-up resources and reduced IT cost.
                <br />
                With our AWS expertise, security and automation processes we
                will provide you the fastest way to move any workload to AWS.
              </p>
            </SectionBox2>
            <SectionBox>
              <p>
                <b>Increase speed and security</b>
              </p>
            </SectionBox>
            <SectionBox2>
              <p>
                Only by choosing the right instance type and size, you can
                significantly reduced network latency and speed up the system in
                some cases by 50%. This is just one example and AWS have
                hundreds of services that can improve speed and security of your
                services.
                <br />
                Our Amazon Web Services team holds multiple certifications that
                includes 'AWS Certified Security - Specialty' as well as
                'Solutions Architect', 'SysOps' and 'DevOps' certificate.
              </p>
            </SectionBox2>
            <SectionBox>
              <p>
                <b>Reduce infrastructure costs</b>
              </p>
            </SectionBox>
            <SectionBox2>
              <p>
                In most cases our clients reduce their infrastructural costs by
                one third while AWS continues to lower the cost of cloud
                computing, storage, database, analytics and other resources.
                <br />
                Our theme is experienced in providing you with best solutions
                and systems like scale your applications, use reserve or spot
                instances, migrate to No-SQL databases, serverless
                infrastructure and event based micro service systems. In the
                process of migrating we will guide you through optimization of
                resources, using appropriate cloud storage, selecting right
                instance types, monitor, track and analyze your service usage,
                autoscaling, consolidated billing and many more.
              </p>
            </SectionBox2>
          </ScrollAnimation>
        </div>
        <ImageBox1>
          <div className="png-img">
            <img src={ilustration1} alt="Migrate to AWS" />
          </div>
          <div className="res-img">
            <img src={ilustration1} alt="Migrate to AWS" />
          </div>
        </ImageBox1>
      </GridStyled1>
    </SvgBg1>
  </Layout>
)

export default MigrateToAws
